import clsx from 'clsx'
import * as React from 'react'
import { useQuery } from 'react-query'
import { useSearch } from '../utils/SearchContext'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { Link } from './Link'
import style from './Search.module.sass'
import { TagGrid } from './TagGrid'

export function Search() {
	const search = useSearch()

	//const [isSearching, setIsSearching] = React.useState<boolean>(false)
	const [searchToggled, setSearchToggled] = React.useState<boolean>(false)
	const searchInput = React.useRef(null)

	const result = useQuery(
		['search', search.query] as const,
		async (context) => {
			const q = context.queryKey[1]
			if (q) {
				const result = await search.search(q)
				return {
					articles: result.articles.listArticle,
					authors: result.authors.listAuthor,
					tags: result.tags.listTag,
				}
			}
			return null
		},
		{ keepPreviousData: true }
	)

	const results = result.data

	return (
		<div className={style.wrapper}>
			<form
				method="post"
				action={`/search?query=${search.query}`}
				className={clsx(
					style.form,
					searchToggled && style.visible,
					(Boolean(results?.authors.length) ||
						Boolean(results?.articles.length) ||
						Boolean(results?.tags.length)) &&
						style.resultsVisible
				)}>
				<input
					placeholder="Hledej"
					ref={searchInput}
					onChange={async (e) => {
						const query = e.target.value
						search.setQuery(query)
					}}
					type="search"></input>
				<ul
					className={clsx(
						style.results,
						(Boolean(results?.authors.length) ||
							Boolean(results?.articles.length) ||
							Boolean(results?.tags.length)) &&
							style.hasResults
					)}>
					{results?.articles.map((article, index) => {
						return (
							<li key={index}>
								{article.url?.url && (
									<Link href={article.url?.url}>
										<div className={style.articleInner}>
											<div className={style.articleImage}>
												{article.mainImage && (
													<ContemberImage
														objectFit="cover"
														layout="fill"
														image={article.mainImage}
													/>
												)}
											</div>
											<h3 className={style.articleTitle}>{article.details?.plainTitle}</h3>
										</div>
									</Link>
								)}
							</li>
						)
					})}
					{Boolean(results?.authors.length) && <h3 className={style.authorsHeadline}>Autoři</h3>}
					{results?.authors.map((author, index) => {
						return (
							<li key={index}>
								{author.url?.url && (
									<Link href={author.url.url}>
										<div className={style.authorInner}>
											<div className={style.authorImage}>
												{author.avatar && (
													<ContemberImage objectFit="cover" layout="fill" image={author.avatar} />
												)}
											</div>
											<h3 className={style.authorTitle}>{author.name}</h3>
										</div>
									</Link>
								)}
							</li>
						)
					})}
					{Boolean(results?.tags.length) && <h3 className={style.authorsHeadline}>Štítky</h3>}
					{Boolean(results?.tags.length) && results?.tags && <TagGrid items={results?.tags} />}
				</ul>
			</form>
			<button
				onClick={() => {
					setSearchToggled(!searchToggled)
					if (searchToggled) {
						search.setQuery(null)
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						//@ts-ignore
						searchInput.current.value = ''
					}
					if (searchInput.current) {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						//@ts-ignore
						searchInput.current.focus()
					}
				}}
				className={style.button}>
				<Icon name={searchToggled ? 'cross' : 'magnifier'}></Icon>
			</button>
		</div>
	)
}
