/* eslint-disable @next/next/no-img-element */
import { CSSProperties, FunctionComponent, useEffect, useRef, useState } from 'react'
import style from './WatchingLogo.module.sass'
import base from './WatchingLogo/base.png'
import eye from './WatchingLogo/eye.png'

const angleToOffset = (angle: number) => {
	return { x: Math.cos(angle), y: Math.sin(angle) }
}

const clampOffset = (value: number) => Math.min(1, Math.max(-1, value))

const ease = (current: number, target: number) => current + (target - current) / 20

export const WatchingLogo: FunctionComponent = () => {
	const targetOffset = useRef({ x: -1, y: 0.2 })
	const currentOffset = useRef(targetOffset.current)
	const [offset, setOffset] = useState(targetOffset.current)

	useEffect(() => {
		let timer: number
		const loop = () => {
			currentOffset.current = {
				x: ease(currentOffset.current.x, targetOffset.current.x),
				y: ease(currentOffset.current.y, targetOffset.current.y),
			}
			setOffset(currentOffset.current)
			if (
				Math.abs(currentOffset.current.x - targetOffset.current.x) > 0.1 ||
				Math.abs(currentOffset.current.y - targetOffset.current.y) > 0.1
			) {
				timer = requestAnimationFrame(loop)
			}
		}
		const onAngleChange = (angle: number) => {
			targetOffset.current = angleToOffset(angle)
			cancelAnimationFrame(timer)
			loop()
		}
		const pointerMoveCallback = (event: PointerEvent) => {
			const { x, y } = event
			const { innerWidth, innerHeight } = window
			const xOffset = clampOffset((x / innerWidth) * 2 - 1)
			const yOffset = clampOffset(y / innerHeight)
			const angle = Math.atan2(yOffset, xOffset)
			onAngleChange(angle)
		}
		const screenOrientationCallback = (event: DeviceMotionEvent) => {
			if (event.accelerationIncludingGravity === null) {
				return
			}
			const { x, y } = event.accelerationIncludingGravity
			if (x === null || y === null) {
				return
			}
			const angle = Math.atan2(clampOffset(y / 100), clampOffset(-x / 10))

			onAngleChange(angle)
		}
		document.addEventListener('pointermove', pointerMoveCallback)
		window.addEventListener('devicemotion', screenOrientationCallback, true)
		return () => {
			document.removeEventListener('pointermove', pointerMoveCallback)
			window.removeEventListener('devicemotion', screenOrientationCallback, true)
			cancelAnimationFrame(timer)
		}
	}, [])

	return (
		<div
			className={style.wrapper}
			style={
				{
					'--WatchingLogo-offset-x': `${offset.x}`,
					'--WatchingLogo-offset-y': `${offset.y}`,
				} as CSSProperties
			}>
			<img className={style.base} src={base.src} width={base.width} height={base.height} alt="" />
			<div className={style.eye}>
				<img className={style.eyeIn} src={eye.src} width={eye.width} height={eye.height} alt="" />
			</div>
			<div className={style.eye}>
				<img className={style.eyeIn} src={eye.src} width={eye.width} height={eye.height} alt="" />
			</div>
		</div>
	)
}
