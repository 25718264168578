import clsx from 'clsx'
import { getBaseUrl } from '../utils/getBaseUrl'
import { Tag, TagProps } from './Tag'
import style from './TagRowGrid.module.sass'

export type TagRowGridProps = {
	title?: React.ReactNode
	small?: boolean
	nav?: boolean
	rows: Array<{
		size?: 'large' | 'medium' | 'small' | 'extraSmall'
		items: Array<TagProps>
		title?: React.ReactNode
	}>
	gtmNavigationSection?: string
}

export function TagRowGrid(props: TagRowGridProps) {
	return (
		<div className={clsx(style.wrapper, props.small && style.small, props.nav && style.nav)}>
			{props.title && <h2 className={clsx(style.title)}>{props.title}</h2>}
			{props.rows.map((row, index) => {
				const size = row.size ? row.size : 'extraSmall'
				return (
					<div key={index} className={style.section}>
						{row.title && <h3 className={clsx(style.sectionTitle)}>{row.title}</h3>}
						<div className={clsx(style.row, style[`view_size_${size}`])}>
							{row.items.map((item, index) => (
								<div key={index} className={style.item}>
									<Tag
										{...item}
										dataAttributes={
											props.gtmNavigationSection !== undefined
												? {
														'track-click': JSON.stringify({
															event: 'clickTo_navigation',
															menu: { type: props.gtmNavigationSection },
															link: {
																text: item.text,
																url: getBaseUrl() + item.url?.url,
															},
														}),
												  }
												: {}
										}
									/>
								</div>
							))}
						</div>
					</div>
				)
			})}
		</div>
	)
}
