import clsx from 'clsx'
import type React from 'react'
import { TagTextColor } from '../../generated/content'
import type { TagResult } from '../data/fragments/TagFragment'
import { Image } from './Image'
import { Link } from './Link'
import style from './Tag.module.sass'

export type TagProps = TagResult & {
	dataAttributes?: {
		[name: string]: string
	}
	bigSidePadding?: boolean
}

const colors: Record<TagTextColor, string> = {
	[TagTextColor.white]: '#FFFFFF',
	[TagTextColor.black]: '#000000',
}

export function Tag(props: TagProps) {
	if (props.url?.url) {
		return (
			<div
				className={style.Wrapper}
				style={
					{
						['--Tag-background-color']: props.backgroundColor,
						['--Tag-color']: colors[props.textColor],
					} as React.CSSProperties
				}>
				<Link
					className={clsx(style.Link, props.bigSidePadding && style.bigSidePadding)}
					href={props.url?.url}
					dataAttributes={{ 'track-list': 'false', ...props.dataAttributes }}>
					{props.icon && (
						<div
							className={style.icon}
							style={
								{
									['--Tag-icon-aspectRatio']: (props.icon.width || 1) / (props.icon.height || 1),
								} as React.CSSProperties
							}>
							<Image
								src={props.icon.url}
								layout="responsive"
								width={props.icon.width}
								height={props.icon.height}
								alt={props.icon.alt}
							/>
						</div>
					)}
					{props.text}
				</Link>
			</div>
		)
	}
	return null
}
