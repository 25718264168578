import clsx from 'clsx'
import type { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from 'react'
import style from './Container.module.sass'

export interface ContainerProps {
	size?: undefined | 'narrow' | 'normal' | 'wide' | 'full'
	disableGutters?: boolean
	disableGuttersMobile?: boolean
	attrs?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
		Record<`data-${string}`, string | undefined>
}

export const Container: FunctionComponent<ContainerProps> = ({
	children,
	size = 'normal',
	disableGutters = false,
	disableGuttersMobile = false,
	attrs,
}) => {
	return (
		<div
			{...attrs}
			className={clsx(
				style.Wrapper,
				style[`View_size_${size}`],
				disableGutters && style.View_disableGutters,
				disableGuttersMobile && style.View_disableGuttersMobile
			)}>
			{children}
		</div>
	)
}
