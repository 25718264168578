import clsx from 'clsx'
import { TopMenuItemPart } from '../../generated/content'
import { useSharedContext } from '../../libs/routing/sharedContext'
import { getUrlFromLinkResult } from '../utils/getUrlFromLinkResult'
import { Link } from './Link'
import style from './NavigationList.module.sass'

export type NavigationList = {
	part: TopMenuItemPart
}

export const NavigationList: React.FunctionComponent<NavigationList> = ({ part }) => {
	const header = useSharedContext().header

	return (
		<nav className={style.wrapper}>
			{[part].map((part) => (
				<ul
					key={part}
					className={clsx(style.list, part == 'secondary' && style.view_navigationSecondary)}>
					{header[part].map(
						(link) =>
							link.link && (
								<li className={style.label} key={link.id}>
									<Link
										className={style.link}
										link={link.link}
										dataAttributes={{
											'track-click': JSON.stringify({
												event: 'clickTo_navigation',
												menu: { type: part === TopMenuItemPart.secondary ? 'menu' : 'header' },
												link: {
													type: 'link',
													text: link.label,
													url: getUrlFromLinkResult(link.link).href,
												},
											}),
										}}>
										{link.label}
									</Link>
								</li>
							)
					)}
				</ul>
			))}
		</nav>
	)
}
