export function truncateString(str: string, num: number, dontAddDots?: boolean) {
	if (str.length <= num) {
		return str
	}

	if (dontAddDots) {
		return str.slice(0, num)
	}
	return str.slice(0, num) + '...'
}
