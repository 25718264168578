import clsx from 'clsx'
import React, { FunctionComponent, useState } from 'react'
import { TopMenuItemPart } from '../../generated/content'
import { useSharedContext } from '../../libs/routing/sharedContext'
import { useAuth } from '../auth/UserContext'
import type { ImageResult } from '../data/fragments/ImageFragment'
import { usePageLoadingState } from '../hooks/usePageLoadingState'
import { truncateString } from '../utils/truncateString'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import style from './Header.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'
import { NavigationList } from './NavigationList'
import { Search } from './Search'
import { TagRowGrid } from './TagRowGrid'
import { WatchingLogo } from './WatchingLogo'

export const Header: FunctionComponent<{
	logo?: ImageResult | null
	hideNavigationList?: boolean
}> = (props) => {
	const { header } = useSharedContext()
	const { hideNavigationList = false } = props
	const logo = props.logo ?? header.logo

	const [isOpen, setIsOpen] = useState(false)

	const [loading] = usePageLoadingState()

	const auth = useAuth()

	React.useEffect(() => {
		setIsOpen(false)
	}, [loading])

	return (
		<header className={style.wrapper}>
			<Container size="wide">
				<div className={clsx(style.main, isOpen && style.view_isOpen)}>
					<button
						className={style.button}
						type="button"
						onClick={() => setIsOpen(!isOpen)}
						aria-label="menu tlačítko"
						data-track-click={JSON.stringify({
							event: 'clickTo_navigation',
							menu: {
								type: 'menu',
							},
							link: { text: isOpen ? 'open' : 'close' }, // isOpen value changes before this event is triggered
						})}></button>
					<div className={style.navigation}>
						<div className={style.navigationPrimaryLeft}>
							{!hideNavigationList && <NavigationList part={TopMenuItemPart.left} />}
						</div>
						<Link
							href="/"
							className={style.navigationLogo}
							ariaLabel="Domů"
							dataAttributes={{
								'track-click': JSON.stringify({
									event: 'clickTo_navigation',
									menu: { type: 'menu' },
									link: {
										type: 'logo',
										text: 'Domů',
										url: '/',
									},
								}),
							}}>
							{logo ? <ContemberImage image={logo} /> : <WatchingLogo />}
						</Link>
						<div className={style.navigationPrimaryRight}>
							{!hideNavigationList && <NavigationList part={TopMenuItemPart.right} />}
						</div>

						<div className={style.navigationMenu}>
							<div className={style.navigationMenuIn}>
								<Container>
									<div className={style.navigationMenuPrimary}>
										<NavigationList part={TopMenuItemPart.left} />
										<NavigationList part={TopMenuItemPart.right} />
									</div>
									{TopMenuItemPart.secondary && (
										<div className={style.navigationMenuSecondary}>
											<NavigationList part={TopMenuItemPart.secondary} />
										</div>
									)}
									<div className={style.navigationMenuTags}>
										{header.tagGrids.map((tagGrid, i) => (
											<div key={i} className={style.navigationMenuTagsRowGrid}>
												<TagRowGrid {...tagGrid} nav gtmNavigationSection="menu" />
											</div>
										))}
									</div>
								</Container>
							</div>
						</div>
					</div>
					<div className={style.headerRight}>
						<Search />
						{auth.status === 'signedOut' && (
							<button
								className={style.userIcon}
								onClick={async () => {
									auth.initSignInIDP()
								}}>
								<Icon name="user" />
							</button>
						)}
						{auth.status === 'signedIn' && (
							<div className={style.userCircle}>
								<Link href="/uzivatel">
									<span>
										{auth.user.nick
											? truncateString(auth.user.nick, 1, true)
											: // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											  truncateString(auth.user.email!, 1, true)}
									</span>
								</Link>
							</div>
						)}
					</div>
				</div>
			</Container>

			{/* <AuthInfo /> */}
		</header>
	)
}
