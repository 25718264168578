import clsx from 'clsx'
import type { TagResult } from '../data/fragments/TagFragment'
import { Tag } from './Tag'
import style from './TagGrid.module.sass'

export type TagGridProps = {
	items: Array<TagResult>
	align?: 'start' | 'center' | 'end'
	size?: 'normal' | 'large'
	bigSidePadding?: boolean
	tagSize?: string | 'normal'
}

export function TagGrid(props: TagGridProps) {
	return (
		<div
			className={clsx(
				style.Wrapper,
				style[props.align ?? 'start'],
				props.size && style[props.size]
			)}>
			{props.items.map((item) => (
				<div key={item.id} className={style.Item}>
					<Tag {...item} bigSidePadding={props.bigSidePadding} />
				</div>
			))}
		</div>
	)
}
